// Entry point for the build script in your package.json
import "./src/jquery"

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import "@hotwired/turbo-rails"

import "bootstrap";
import "@fortawesome/fontawesome-free/js/all";
import "chartkick/chart.js"
import "flatpickr";

$(document).on('turbo:load', function() {
  var today = new Date()
  var eigthteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate())

  $('.dob-datepicker').flatpickr({
    dateFormat: 'Y-m-d',
    altFormat: 'd M Y',
    altInput: true,
    maxDate: eigthteenYearsAgo
  });

  $('.completed-at-datepicker').flatpickr({
    dateFormat: 'Y-m-d',
    altFormat: 'd M Y',
    altInput: true,
    maxDate: today
  });

  $('.status-datepicker').flatpickr({
    dateFormat: 'Y-m-d',
    altFormat: 'd M Y',
    altInput: true
  });
});
